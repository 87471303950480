@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* src/index.css */
.carousel-container {
  max-width: 1200px;
  margin: 0 auto;
}

.carousel-slide img {
  border-radius: 8px;
}
